import React from "react";
import PropTypes from "prop-types";
import "./PDFEditor.scss";
import PageEditor from "./PageEditor";
import ArrangerPage from "./ArrangerPage";
import { convertPDFToImages, debugPrint } from "../../utilities/Utilities";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import APIHeader from "../../configs/APIHeader";
import ChevronDown from "../../images/chevron-down.svg";
import AddNewPageVector from "../../images/add-new-page-vector.svg";

class PDFEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      epaper_path: null,
      pages: [],
      isArrangerMenuActive: false,
    };

    this.brackets = {};
    this.pageEditors = {};
    this.inputFile = React.createRef();
    this.processPDF = this.processPDF.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.remapBracket = this.remapBracket.bind(this);
  }

  componentDidMount() {
    // If pdf passed in (new paper)
    if (this.props.pdf !== undefined && this.props.pdf !== null) {
      this.processPDF(this.props.pdf);
    }

    // If images passed in (existing paper)
    if (this.props.pages.length > 0) {
      this.setState({ pages: this.props.pages });
      this.setState({ epaper_path: this.props.epaper_path });
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Reorder Brackets
    const bracketsArray = [];
    for (var key in this.brackets) {
      if (this.brackets.hasOwnProperty(key)) {
        bracketsArray.push(this.brackets[key]);
      }
    }
    const [removedBracket] = bracketsArray.splice(startIndex, 1);
    bracketsArray.splice(endIndex, 0, removedBracket);
    bracketsArray.forEach((brackets, index) => {
      const pageKey = "page_" + index;
      this.brackets[pageKey] = brackets;
      for (var key in brackets) {
        brackets[key].page = index;
      }
    });

    var newBrackets = this.getProcessedBrackets();
    this.props.updateAllBrackets(newBrackets);

    Object.keys(this.pageEditors).forEach((key, index) => {
      try {
        this.pageEditors[key].forceUpdateBrackets(newBrackets);
      } catch (e) {}
    });

    return result;
  }

  remapBracket(mode, index) {
    const pageKey = "page_" + index;
    var tempBrackets = [];
    if (mode === 0) {
      Object.keys(this.brackets).forEach((eachPageBracket) => {
        if (eachPageBracket !== pageKey) {
          tempBrackets.push(this.brackets[eachPageBracket]);
        }
      });
    }
    if (mode === 1) {
      Object.keys(this.brackets).forEach((eachPageBracket) => {
        tempBrackets.push(this.brackets[eachPageBracket]);
      });
      tempBrackets.splice(index, 0, {});
    }
    var tempReturnBrackets = {};
    tempBrackets.forEach((eachNewPageBracket, newIndex) => {
      let newPageKey = "page_" + newIndex;

      Object.keys(eachNewPageBracket).forEach((eachBracket) => {
        eachNewPageBracket[eachBracket].page = newIndex;
      });

      tempReturnBrackets[newPageKey] = eachNewPageBracket;
    });
    this.brackets = tempReturnBrackets;
    var newBrackets = this.getProcessedBrackets();
    this.props.updateAllBrackets(newBrackets);
    Object.keys(this.pageEditors).forEach((key, index) => {
      try {
        this.pageEditors[key].forceUpdateBrackets(newBrackets);
      } catch (e) {}
    });
  }

  deletePage(index) {
    this.state.pages.splice(index, 1);
    this.setState({ pages: this.state.pages });
    this.remapBracket(0, index);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const pages = this.reorder(
      this.state.pages,
      result.source.index,
      result.destination.index
    );

    this.setState({ pages: pages });

    this.props.pagesUpdated(pages);
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      filter: isDragging ? "brightness(30%)" : "",
      ...draggableStyle,
    };
  }

  async uploadPageToServer(image, callback) {
    let session = JSON.parse(localStorage.getItem("session"));
    const formData = new FormData();

    var imageFile = null;
    if (image instanceof File) {
      imageFile = image;
    } else {
      const imageBlob = await (await fetch(image)).blob();
      imageFile = new File([imageBlob], "image.jpg", {
        type: "image/jpeg",
        lastModified: new Date(),
      });
    }

    formData.append("image", imageFile);
    formData.append("user_id", session["userID"]);
    formData.append("jwt", session["jwt"]);
    axios({
      method: "post",
      url: "epage_upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        apikey: APIHeader.apiKey,
      },
    }).then((response) => {
      callback(response);
    });
  }

  async onUploadFile(index, e, replace = false) {
    this.props.setLoadingScreen(true, 0.01);
    const file = e.target.files[0];
    const fileExt = file.name.split(".").reverse()[0];
    const pagesToAdd = [];
    var imagesToAdd = [];

    if (fileExt === "pdf") {
      imagesToAdd = await convertPDFToImages(file);
    } else {
      var reader = new FileReader();
      const promise = new Promise((resolve) => {
        reader.onloadend = function () {
          resolve(reader.result);
        };
      });
      reader.readAsDataURL(file);

      imagesToAdd = [await promise];
      // Resize images
      for (let i = 0; i < imagesToAdd.length; i++) {
        const load = (url) =>
          new Promise((resolve) => {
            var img = document.createElement("img");
            img.onload = () => resolve(img);
            img.src = url;
          });

        let img = await load(imagesToAdd[i]);

        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 4000;
        var MAX_HEIGHT = 4000;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        imagesToAdd[i] = canvas.toDataURL("image/jpeg");

        img = null;
        ctx = null;
      }
    }
    this.props.setLoadingScreen(true, 0.5);
    imagesToAdd.forEach(async (image) => {
      const page = {
        id: null,
        image: image,
      };
      pagesToAdd.push(page);
      this.uploadPageToServer(image, (response) => {
        page["id"] = response.data.epage_id;
        this.props.setLoadingScreen(false, 0);

        this.props.pagesUpdated(this.state.pages);
      });
    });

    this.state.pages.splice(index, replace ? 1 : 0, ...pagesToAdd);
    this.setState({ pages: this.state.pages });
    this.props.pagesUpdated(this.state.pages);
    if (!replace) {
      this.remapBracket(1, index);
    }
  }

  // Processing whole uploaded pdf. Converts each pdf pages to image, then upload
  // to server
  async processPDF(pdf) {
    let images = await convertPDFToImages(pdf);
    var pages = [];
    images.forEach(async (image) => {
      const page = {
        id: null,
        image: image,
      };
      pages.push(page);
      let session = JSON.parse(localStorage.getItem("session"));
      const formData = new FormData();
      const imageBlob = await (await fetch(image)).blob();
      const imageFile = new File([imageBlob], "image.jpg", {
        type: "image/jpeg",
        lastModified: new Date(),
      });
      formData.append("image", imageFile);
      formData.append("user_id", session["userID"]);
      formData.append("jwt", session["jwt"]);
      axios({
        method: "post",
        url: "epage_upload",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          apikey: APIHeader.apiKey,
        },
      }).then((response) => {
        page["id"] = response.data.epage_id;
        var doneUpload = true;
        pages.forEach((page) => {
          if (page.id == null) {
            doneUpload = false;
          }
        });
        if (doneUpload) {
          this.props.pagesUpdated(pages);
        }
      });
    });
    this.setState({ pages: pages });
    this.props.pagesUpdated(pages);
    this.props.pdfFullyLoadedFunc(pages);
  }

  getProcessedBrackets() {
    var brackets = [];
    Object.keys(this.brackets).forEach((eachPage) => {
      Object.keys(this.brackets[eachPage]).forEach((eachBracket) => {
        var currentBracket = this.brackets[eachPage][eachBracket];
        brackets.push({
          page: currentBracket["page"],
          top: currentBracket["topLeft"][1],
          left: currentBracket["topLeft"][0],
          width:
            currentBracket["bottomRight"][0] - currentBracket["topLeft"][0],
          height:
            currentBracket["bottomRight"][1] - currentBracket["topLeft"][1],
          area_type: currentBracket["type"],
          area_url: currentBracket["url"],
        });
      });
    });

    return brackets;
  }

  render() {
    return (
      <div className="pdf-editor-main">
        <div
          className={
            "filter-parent pdf-editor " +
            (this.state.isArrangerMenuActive ? "apply-dark-overlay" : "")
          }
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              maxHeight: 900,
              overflowY: "scroll",
            }}
          >
            <div className="pdf-editor-parent ">
              {this.props.test}
              <div className="pdf-editor-pages-parent ">
                {this.state.pages.map((eachPage, index) => (
                  <PageEditor
                    ref={(e) => {
                      this.pageEditors[`page-editor-${index}`] = e;
                    }}
                    brackets={this.props.bracketsInfo}
                    key={index}
                    index={index}
                    image={
                      eachPage.image
                        ? eachPage.image
                        : this.state.epaper_path + eachPage.page_path
                    }
                    total={this.state.pages.length}
                    updateBrackets={(brackets, remindUpdate) => {
                      this.brackets[`page_${index}`] = brackets;
                      this.props.updateAllBrackets(
                        this.getProcessedBrackets(),
                        remindUpdate
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="menu-wrapper">
          <div
            className={
              "arrange-editor " +
              (this.state.isArrangerMenuActive ? "show" : "")
            }
          >
            <div className="title-input">页数管理</div>
            <div className="arranger">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        gap: "14px",
                        paddingBottom: "16px",
                      }}
                    >
                      {this.state.pages.map((eachPage, index) => (
                        <Draggable
                          key={index}
                          draggableId={"page-" + index}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="arranger-page"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={this.getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ArrangerPage
                                key={index}
                                index={index}
                                image={
                                  eachPage.image
                                    ? eachPage.image
                                    : this.state.epaper_path +
                                      eachPage.page_path
                                }
                                total={this.state.pages.length}
                                deletePage={() => {
                                  this.deletePage(index);
                                }}
                                onUploadFile={(e, replace = false) => {
                                  this.onUploadFile(index, e, replace);
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                      <div className="arranger-page">
                        <div className="page-arranger-parent">
                          <label className="add-button btn page-arranger-page add-page-button">
                            <input
                              type="file"
                              name="upload"
                              accept="image/jpeg,image/png,application/pdf"
                              onChange={(e) => {
                                this.onUploadFile(this.state.pages.length, e);
                              }}
                            />
                            <img src={AddNewPageVector} alt="Add New Page" />
                          </label>
                          <div className="page-number add-new-page-label">
                            新增
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
            className="top-menu-button"
            onClick={() => {
              this.setState({
                isArrangerMenuActive: !this.state.isArrangerMenuActive,
              });
            }}
          >
            <img
              className={this.state.isArrangerMenuActive ? "up" : ""}
              src={ChevronDown}
              alt="menu"
            />
          </div>
        </div>
      </div>
    );
  }
}

PDFEditor.propTypes = {
  exitFunc: PropTypes.func,
  updateAllBrackets: PropTypes.func,
  pdfFullyLoadedFunc: PropTypes.func,
  pdf: PropTypes.object,
  images: PropTypes.array,
  bracketsInfo: PropTypes.array,
  remapBracket: PropTypes.func,
};

PDFEditor.defaultProps = {
  exitFunc: () => {
    debugPrint("Exiting...");
  },
  updateAllBrackets: (e) => {
    debugPrint(e);
  },
  pdfFullyLoadedFunc: () => {
    debugPrint("Loaded PDF");
  },
  remapBracket: (mode, page) => {
    debugPrint(`Remapping brackets with mode ${mode} - page ${page}`);
  },
  pdf: null,
  images: [],
  bracketsInfo: [],
};

export default PDFEditor;
