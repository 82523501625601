import PropTypes from "prop-types";
import React from "react";
import {
  DEFAULT_MONTHLY_READING_TRENDS_COUNT,
  DEFAULT_DAILY_READING_TRENDS_COUNT,
} from "../../configs/Default";
import { isValidMonth, convert } from "../../utilities/Utilities";
import "./ReadingTrends.scss";

class ReadingTrends extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentReadAmount: null,
    };

    this.getBarsHeight = this.getBarsHeight.bind(this);
    this.isNaNChecker = this.isNaNChecker.bind(this);
    this.zeroToOne = this.zeroToOne.bind(this);
    this.getBarDays = this.getBarDays.bind(this);
    this.getBarFontColor = this.getBarFontColor.bind(this);

    this.allMonths = this.state.allMonths;
    this.maxHeight = 100;
    this.minHeight = 20;
    this.backgroundColor = "background: rgba(255, 67, 73, 0.25)";
  }

  componentDidMount() {
    this._isMounted = true;
    this.getBarDays();
  }

  isNaNChecker(rate) {
    if (!isFinite(rate) || isNaN(rate)) rate = 0;
    return rate;
  }

  zeroToOne(number) {
    if (number === 0) number = 1;
    return number;
  }

  getBarsHeight(count) {
    var finalHeight = 0;
    if (count !== undefined && count !== null && !isNaN(count) && count > 0) {
      finalHeight = (count / this.props.highest) * this.maxHeight;
    }
    if (isNaN(finalHeight)) {
      finalHeight = 0;
    }

    return Math.max(Math.min(this.maxHeight, finalHeight), this.minHeight);
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  increasing() {
    if (!this.props.dailyMode) {
      return this.props.data["m-0"] >= this.props.data["m-1"];
    } else {
      return this.props.data2["m-0"] >= this.props.data2["m-1"];
    }
  }
  getBarDays(index) {
    var date = new Date();
    date.setDate(date.getDate() - 7);
    for (let i = 0; i < index; i++) {
      date.setDate(date.getDate() + 1);
      var currentDay = date.getDate();
    }
    return currentDay;
  }

  getBarsColor(count) {
    var finalBackgroundColor = "#00EAB6";
    if (this.props.colorCode === "green") {
      if (this.props.highest !== count) {
        finalBackgroundColor = "rgba(255, 67, 73, 0.25)";
      } else {
        finalBackgroundColor = "rgba(255, 67, 73, 1)";
      }
    }

    if (this.props.colorCode === "red") {
      if (this.props.highest !== count) {
        finalBackgroundColor = "red";
      } else if (this.props.lowest === count) {
        finalBackgroundColor = "#00EAB6";
      } else {
        finalBackgroundColor = "rgba(255, 255, 255, 0.35)";
      }
    }

    return finalBackgroundColor;
  }

  getBarFontColor(count) {
    var finalFontColor = "white";
    if (this.props.highest !== count) {
      finalFontColor = "rgba(179, 47, 54, 1)";
    } else {
      finalFontColor = "white";
    }
    return finalFontColor;
  }

  render() {
    return (
      <div style={{ userSelect: "none " }}>
        <div
          style={{
            color: "rgba(0, 0, 0, 0.75)",
            fontWeight: "normal",
            fontSize: "12px",
          }}
        >
          {!this.props.dailyMode ? " 过去6个月" : "过去7天"}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            gap: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="current-read-amount">
              {this.state.currentReadAmount}
            </div>

            <div className="total-number">
              {!this.props.dailyMode
                ? convert(this.props.data["total"])
                : convert(this.props.data2["total"])}
            </div>
            <div
              style={{
                borderRadius: "12px",
                backgroundColor: this.props.isRegUserCount
                  ? `${
                      this.increasing()
                        ? "rgba(0, 234, 182, 0.25) "
                        : "rgba(238, 65, 57, 0.25)"
                    }`
                  : `${
                      this.increasing()
                        ? "rgba(238, 65, 57, 0.25)"
                        : "rgba(0, 234, 182, 0.25) "
                    }`,
                fontSize: 10,
                height: "auto",
                paddingRight: 8,
                paddingLeft: 8,
                paddingTop: 4,
                paddingBottom: 4,
                width: "min-content",
                color: this.props.isRegUserCount
                  ? `${this.increasing() ? "#03A27E" : "red"}`
                  : `${this.increasing() ? "red" : "#03A27E"}`,
                border: this.props.isRegUserCount
                  ? `${
                      this.increasing() ? "1px solid #03A27E" : "1px solid red"
                    }`
                  : `${
                      this.increasing() ? "1px solid red" : "1px solid #03A27E"
                    }`,
                justifyContent: "flex-start",
              }}
            >
              {!this.props.dailyMode
                ? this.props.data["m-0"] >= this.props.data["m-1"]
                  ? "+"
                  : "-"
                : this.props.data2["m-0"] >= this.props.data2["m-1"]
                ? "+"
                : "-"}
              {!this.props.dailyMode
                ? parseInt(
                    this.isNaNChecker(
                      100 -
                        (this.zeroToOne(this.props.data["m-0"]) /
                          this.zeroToOne(this.props.data["m-1"])) *
                          100
                    )
                  )
                : parseInt(
                    this.isNaNChecker(
                      (this.zeroToOne(this.props.data2["m-0"]) /
                        this.zeroToOne(this.props.data2["m-1"])) *
                        100
                    )
                  )}
              %
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: "0.25em",
            }}
          >
            {Object.keys(
              !this.props.dailyMode ? this.props.data : this.props.data2
            )
              .sort()
              .reverse()
              .map((eachMonth, index) => {
                if (isValidMonth(eachMonth)) {
                  return (
                    <div
                      onMouseEnter={() => {
                        this.setState({
                          currentReadAmount: !this.props.dailyMode
                            ? this.props.data[eachMonth]
                            : this.props.data2[eachMonth],
                        });
                      }}
                      onMouseOut={() => {
                        this.setState({
                          currentReadAmount: null,
                        });
                      }}
                      id={this.props.data[eachMonth]}
                      key={"bars" + eachMonth}
                      index={index}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        minHeight: this.minHeight,
                        maxHeight: this.maxHeight,
                        height: this.getBarsHeight(
                          !this.props.dailyMode
                            ? this.props.data[eachMonth]
                            : this.props.data2[eachMonth]
                        ),
                        width: 26,
                        backgroundColor: this.getBarsColor(
                          !this.props.dailyMode
                            ? this.props.data[eachMonth]
                            : this.props.data2[eachMonth]
                        ),
                        borderRadius: "8px",
                      }}
                    >
                      {this.props.showDays ? (
                        <div
                          style={{
                            color: this.getBarFontColor(
                              !this.props.dailyMode
                                ? this.props.data[eachMonth]
                                : this.props.data2[eachMonth]
                            ),
                            paddingBottom: "0.2em",
                            fontSize: "12px",
                            fontFamily: "Noto Sans SC",
                            fontWeight: 700,
                          }}
                        >
                          {this.getBarDays(index)}
                        </div>
                      ) : null}
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>
    );
  }
}

ReadingTrends.propTypes = {
  data: PropTypes.object,
  data2: PropTypes.object,
  highest: PropTypes.number,
  colorCode: PropTypes.string,
  isRegUserCount: PropTypes.bool,
  lowest: PropTypes.number,
  dailyMode: PropTypes.bool,
  showDays: PropTypes.bool,
};

ReadingTrends.defaultProps = {
  data: DEFAULT_MONTHLY_READING_TRENDS_COUNT,
  data2: DEFAULT_DAILY_READING_TRENDS_COUNT,
  highest: 0,
  colorCode: "green",
  isRegUserCount: true,
  lowest: 0,
  dailyMode: false,
  showDays: false,
};
export default ReadingTrends;
