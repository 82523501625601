import React from "react";
import CardFrame from "../widgets/CardFrame";
import "./Pages.scss";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import GenericButton from "../widgets/GenericButton";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autosave: false,
      refresh: false,
      autosaveFrequency: 2,
      refreshFrequency: 5,
    };
    this.logOut = this.logOut.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
  }

  componentDidMount() {
    this.saveSettings();
  }

  logOut() {
    window.location.href = `/login`;
  }

  saveSettings() {
    this.setState({
      refresh: localStorage.getItem("refresh") === "true" ? true : false,
      autosave: localStorage.getItem("autosave") === "true" ? true : false,
      refreshFrequency: localStorage.getItem("refreshFrequency"),
      autosaveFrequency: localStorage.getItem("autosaveFrequency"),
    });
  }
  render() {
    return (
      <div
        className="settings-filter"
        style={{ paddingTop: "5em", paddingBottom: "5em" }}>
        <div>
          <div className="title-input">电子报页面</div>

          <div style={{ paddingBottom: "1em" }}>
            <CardFrame backgroundColor="rgba(255, 67, 73, 0.13)">
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  flexDirection: "column",
                }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: "1em",
                  }}>
                  <div className="title-input">自动存档</div>
                  <div>
                    <IOSSwitch
                      onChange={(e) => {
                        localStorage.setItem("autosave", e.target.checked);
                        this.setState({ autosave: e.target.checked });
                      }}
                      checked={this.state.autosave}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}>
                  <div
                    className="title-input"
                    style={{ whiteSpace: " nowrap" }}>
                    存档频率
                  </div>

                  <div
                    className="add-page-filter"
                    style={{ padding: 0, paddingLeft: "0.5em" }}>
                    <div>
                      <div
                        className="filter-parent"
                        style={{ background: "white" }}>
                        <input
                          className="filter-input"
                          type="text"
                          maxLength="2"
                          pattern="[0-9]+"
                          onChange={(e) => {
                            localStorage.setItem(
                              "autosaveFrequency",
                              e.target.value
                            );
                            this.setState({
                              autosaveFrequency: e.target.value,
                            });
                          }}
                          placeholder="2分钟"
                          value={this.state.autosaveFrequency}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardFrame>
          </div>
        </div>
        <div>
          <div className="title-input">分析页面</div>

          <CardFrame backgroundColor="rgba(255, 67, 73, 0.13)">
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                flexDirection: "column",
              }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  paddingBottom: "1em",
                }}>
                <div className="title-input">自动刷新</div>
                <div>
                  <IOSSwitch
                    onChange={(e) => {
                      localStorage.setItem("refresh", e.target.checked);
                      this.setState({ refresh: e.target.checked });
                    }}
                    checked={this.state.refresh}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}>
                <div className="title-input" style={{ whiteSpace: " nowrap" }}>
                  刷新频率
                </div>

                <div
                  className="add-page-filter"
                  style={{ padding: 0, paddingLeft: "0.5em" }}>
                  <div>
                    <div
                      className="filter-parent"
                      style={{ background: "white" }}>
                      <input
                        maxLength="2"
                        pattern="\d*"
                        className="filter-input"
                        type="text"
                        onChange={(e) => {
                          localStorage.setItem(
                            "refreshFrequency",
                            e.target.value
                          );
                          this.setState({ refreshFrequency: e.target.value });
                        }}
                        placeholder="5分钟"
                        value={this.state.refreshFrequency}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardFrame>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "2em",
            paddingTop: "1em",
          }}>
          <div className="logout-button" onClick={this.logOut}>
            登出
          </div>
        </div>

        <div className="add-page-filter" style={{ padding: 0 }}>
          <GenericButton
            fullWidth={true}
            fontSize={16}
            label="存档"
            onClick={() => this.saveSettings}
          />
          <div
            style={{
              marginBottom: "2em",
            }}
          />
        </div>
      </div>
    );
  }
}

export default SettingsPage;
