import React from "react";
import SearchIcon from "../../images/search.svg";
import {convertToEpoch, getTodayDateString, jsonDataValid} from "../../utilities/Utilities";
import EPaperCard from "../widgets/E-PaperCard";
import {getEPaperList} from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";
import PageNavigation from "../widgets/PageNavigation";
import LoadingScreen from "../widgets/LoadingScreen";

class EPaperListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            searchType: 0,
            startDate: getTodayDateString(-60),
            endDate: getTodayDateString(),
            ePaperListDraft: [],
            ePaperListComplete: [],
            searchText: "",
            totalPagesDraft: 0,
            currentPageDraft: 0,
            totalPagesComplete: 0,
            currentPageComplete: 0,
            showAlertDialog: false,
            alertDialogTitle: "Message Dialog Title",
            alertDialogMessage: "Message Dialog Message",
            alertDialogAction: null,
            transparency: true,
            showAlertDialogButton: true,
            buttonDisabled: true,
            loading: true
        };

        this.itemPerPage = 8;

        this.getEPaperList = this
            .getEPaperList
            .bind(this);
        this.enterAddEPaper = this
            .enterAddEPaper
            .bind(this);
        this.enterDashboardlogin = this
            .enterDashboardlogin
            .bind(this);
        this.enableAlertDialog = this
            .enableAlertDialog
            .bind(this);
        this.disableAlertDialog = this
            .disableAlertDialog
            .bind(this);
        this.onPageChange = this
            .onPageChange
            .bind(this);
        this.refresh = this
            .refresh
            .bind(this);
        this.searchHashTag = this
            .searchHashTag
            .bind(this);
        this.handleKeyPress = this
            .handleKeyPress
            .bind(this);
    }

    componentDidMount() {
        this.refresh();

        window.addEventListener("search-hashtag", this.searchHashTag);
    }

    componentWillUnmount() {
        window.removeEventListener("search-hashtag", this.searchHashTag);
    }

    enableAlertDialog(title, message, action = null) {
        this.setState({alertDialogTitle: title, alertDialogMessage: message, showAlertDialog: true, alertDialogAction: action});
    }

    disableAlertDialog() {
        this.setState({showAlertDialog: false});
    }

    refresh() {
        this.getEPaperList(0);
        this.getEPaperList(1);
    }

    searchHashTag(hashtag) {
        this.setState({
            searchType: 1,
            searchText: hashtag.detail
        }, this.refresh);
    }

    async getEPaperList(mode = 1, startDate = null, endDate = null, firstCall = true) {
        var currentCallTime = Date.parse(new Date()) / 1000;
        var pastCallTime = Date.parse(new Date()) / 1000;

        var proceed = !firstCall;
        if (firstCall) {
            if (currentCallTime - parseInt(localStorage.getItem("GetListCallTime")) > 5) {
                proceed = true;
            }
        }

        proceed = true;

        if (proceed || !jsonDataValid(localStorage.getItem("EPaperDataComplete")) || !jsonDataValid(localStorage.getItem("EPaperDataDraft"))) {
            if (startDate === null || startDate === undefined) {
                startDate = convertToEpoch(this.state.startDate);
            }

            if (endDate === null || endDate === undefined) {
                endDate = convertToEpoch(this.state.endDate) + 86400;
            }
            var getListResult = await getEPaperList(mode, startDate, endDate, mode === 0
                ? this.state.currentPageDraft
                : this.state.currentPageComplete, this.itemPerPage, this.state.searchType === 0
                ? this.state.searchText
                : "", this.state.searchType === 1
                ? this.state.searchText
                : "");

            if (getListResult.status === APIResult.SUCCESS) {
                if (mode === 0) {
                    this.setState({ePaperListDraft: getListResult.data, totalPagesDraft: getListResult.total});
                    localStorage.setItem("EPaperDataDraft", JSON.stringify(getListResult.data));
                    localStorage.setItem("EPaperTotalDraft", JSON.stringify(getListResult.total));
                    localStorage.setItem("GetListCallTime", pastCallTime);
                }
                if (mode === 1) {
                    this.setState({ePaperListComplete: getListResult.data, totalPagesComplete: getListResult.total});
                    localStorage.setItem("EPaperDataComplete", JSON.stringify(getListResult.data));
                    localStorage.setItem("EPaperTotalComplete", JSON.stringify(getListResult.total));
                    localStorage.setItem("GetListCallTime", pastCallTime);
                }
            }
        } else {
            // Do localstorage extraction
            this.setState({
                ePaperListComplete: JSON.parse(localStorage.getItem("EPaperDataComplete")),
                ePaperListDraft: JSON.parse(localStorage.getItem("EPaperDataDraft")),
                totalPagesComplete: JSON.parse(localStorage.getItem("EPaperTotalComplete")),
                totalPagesDraft: JSON.parse(localStorage.getItem("EPaperTotalDraft"))
            });
            localStorage.setItem("GetListCallTime", pastCallTime);
        }
        this.setState({loading: false});
    }

    handleKeyPress = (event) => {
        if (event.key === "Enter") {
            this.getEPaperList();
        }
    };

    enterAddEPaper(id = 0) {
        window.location.href = `/addepaper?id=${id}`;
    }

    enterDashboardlogin() {
        window.location.href = `/login`;
    }

    onPageChange(mode = 1, e) {
        this.setState({
            currentPageDraft: mode === 0
                ? e
                : this.state.currentPageDraft,
            currentPageComplete: mode === 1
                ? e
                : this.state.currentPageComplete
        }, () => {
            this.getEPaperList(mode);
        });
    }

    render() {
        return (
            <div style={{display:"contents", width: "100%"}}>
                {this.state.loading
                    ? <LoadingScreen/>
                    : null}
                <div className="dashboard-content">
                    <div className="ePaperPage-filter">
                        <div className="title-input">搜索字眼</div>
                        <div
                            style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "1em",
                            gap: 10
                        }}>
                            <div
                                style={{
                                flex: 1
                            }}
                                className="filter-parent">
                                <input
                                    onKeyDownCapture={this.handleKeyPress}
                                    className="filter-input"
                                    type="text"
                                    placeholder="搜索"
                                    value={this.state.searchText}
                                    onChange={(e) => {
                                    this.setState({searchText: e.target.value});
                                }}/>
                                <img
                                    style={{    
                                        position: "absolute",
                                        right: "1em",
                                        height: "28px"}}
                                    onClick={()=>this.getEPaperList()}
                                    className="button-icon button-desktop"
                                    src={SearchIcon}
                                    alt="search"/>
                            </div>
                            <div className="filter-parent">
                                <select
                                    className="filter-input"
                                    type="text"
                                    value={this.state.searchType}
                                    onChange={(e) => {
                                    this.setState({
                                        searchType: Number(e.target.value)
                                    });
                                }}>
                                    <option value="0">标题</option>
                                    <option value="1">关键字</option>
                                </select>
                            </div>
                        </div>
                        <div className="date-filter-parent">
                            <div>
                                <div className="title-input">开始日期</div>
                                <div className="filter-parent">
                                    <input
                                        className="filter-input"
                                        type="date"
                                        value={this.state.startDate}
                                        onChange={(e) => {
                                        this.setState({
                                            startDate: e.target.value
                                        }, this.refresh);
                                    }}/>
                                </div>
                            </div>
                            <div>
                                <div className="title-input">结束日期</div>
                                <div className="filter-parent">
                                    <input
                                        className="filter-input"
                                        type="date"
                                        value={this.state.endDate}
                                        onChange={(e) => {
                                        this.setState({
                                            endDate: e.target.value
                                        }, this.refresh);
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="new-section m-t-m">
                        <div className="title-input">草稿</div>
                        <div className="e-paper-list m-t-m">
                            {this.state.ePaperListDraft.length === 0
                                ? (
                                    <div>目前没有草稿</div>
                                )
                                : null}
                            {this
                                .state
                                .ePaperListDraft
                                .map((ePaper, index) => {
                                    return (<EPaperCard
                                        key={index}
                                        title={ePaper.title}
                                        path={ePaper.epaper_path}
                                        thumbnail={ePaper.epaper_path + ePaper.epaper_img_thumbnail[0]}
                                        date={ePaper.publish_date_string}
                                        tags={ePaper.hashtags}
                                        enterEdit={() => {
                                        this.enterAddEPaper(ePaper.epaper_id);
                                    }}/>);
                                })}
                        </div>
                    </div>
                    {this.state.totalPagesDraft < 1
                        ? null
                        : (<PageNavigation
                            currentPage={this.state.currentPageDraft + 1}
                            total={Math.ceil(this.state.totalPagesDraft / this.itemPerPage)}
                            onPageChange={(e) => this.onPageChange(0, e - 1)}/>)}

                    <div className="new-section m-t-m">
                        <div className="title-input">过去</div>
                        <div className="e-paper-list m-t-m">
                            {this.state.ePaperListComplete.length === 0
                                ? (
                                    <div>目前没有过去的电子报</div>
                                )
                                : null}
                            {this
                                .state
                                .ePaperListComplete
                                .map((ePaper, index) => {
                                    return (<EPaperCard
                                        key={index}
                                        title={ePaper.title}
                                        path={ePaper.epaper_path}
                                        thumbnail={ePaper.epaper_path + ePaper.epaper_thumbnail}
                                        date={ePaper.publish_date_string}
                                        tags={ePaper.hashtags}
                                        enterEdit={() => {
                                        this.enterAddEPaper(ePaper.epaper_id);
                                    }}/>);
                                })}
                        </div>
                    </div>
                    {this.state.totalPagesComplete < 1
                        ? null
                        : (<PageNavigation
                            currentPage={this.state.currentPageComplete + 1}
                            total={Math.ceil(this.state.totalPagesComplete / this.itemPerPage)}
                            onPageChange={(e) => this.onPageChange(1, e - 1)}/>)}
                </div>
            </div>
        );
    }
}

export default EPaperListPage;
