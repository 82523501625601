import APIHeader from "../configs/APIHeader";
import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import { debugPrint } from "./Utilities";
import { createBrowserHistory } from "history";

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

function fillUpSessionInfo(body) {
    let session = JSON.parse(localStorage.getItem("session"));
    if (session !== undefined && session !== null) {
        try {
            body["user_id"] = session["userID"];
            body["jwt"] = session["jwt"];
        } catch (error) {
            localStorage.clear("session");
            createBrowserHistory().push("/login?to=5");
            window.location.reload();
            return false;
        }
    }
    else
    {
        createBrowserHistory().push("/login?to=5");
        window.location.reload();
    }
    return body;
}

async function callAPI(body, target, defaultReturn, needSessionInfo=true) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": APIHeader.contentType,
            apikey: APIHeader.apiKey,
        },
        body: JSON.stringify(needSessionInfo ? fillUpSessionInfo(body) : body, getCircularReplacer()),
    };

    debugPrint(target);
    debugPrint(body);

    var proceed = true;

    Object.values(body).forEach((param) => {
        if (param === null || param === undefined) {
            proceed = false;
        }
    });

    if (proceed) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return await fetch(target, requestOptions)
            .then((response) => response.json())
            .then((responseData) => {
                debugPrint(responseData);
                try {
                    if (responseData.status === APIResult.INVALID_SESSION) {
                        createBrowserHistory().push("/login?to=1");
                        window.location.reload();
                    } else if (responseData.status === APIResult.INVALID) {
                        localStorage.clear();
                        createBrowserHistory().push("/login?to=5");
                        window.location.reload();
                    }
                } catch (e) {
                    debugPrint(e);
                }
                return responseData;
            })
            .catch((error) => {
                console.warn(error);
                return defaultReturn;
            });
    } else {
        return defaultReturn;
    }
}

async function callAPIWithXMLHR(
    body,
    target,
    defaultReturn,
    progressCallback,
    finalCallback
) {
    body = fillUpSessionInfo(body);

    debugPrint(target);
    debugPrint(body);

    const xmlHR = new XMLHttpRequest();
    await new Promise((resolve) => {
        xmlHR.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                progressCallback(event.loaded / event.total);
            }
        });
        xmlHR.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
            }
        });
        xmlHR.addEventListener("loadend", () => {
            resolve(xmlHR.readyState === 4 && xmlHR.status === 200);
        });
        xmlHR.open("POST", target, true);
        xmlHR.setRequestHeader("Content-Type", APIHeader.contentType);
        xmlHR.setRequestHeader("apikey", APIHeader.apiKey);
        xmlHR.responseType = "json";
        xmlHR.onload = function (e) {
            if (this.status === 200) {
                finalCallback(this.response);
            } else {
                finalCallback(defaultReturn);
            }
        };
        xmlHR.send(JSON.stringify(body));
    });
}

export async function login(id, password) {
    var body = {
        user_signin: id,
        user_pw: password,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.login);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [], 
    }, false);
}

export async function addNewEPaper(body, progressCallback, callback) {
    var target = APIPaths.apiEndPoint.concat(APIPaths.pdfUpload);

    return callAPIWithXMLHR(
        body,
        target,
        {
            status: APIResult.TIME_OUT,
            status_message: "-",
            t: [],
        },
        progressCallback,
        callback
    );
}

export async function updateEPaper(
    epaperId,
    title,
    hashtags,
    publishTime,
    status,
    coordinates,
    epages
) {
    var body = {
        epaper_id: epaperId,
        title: title,
        hashtags: hashtags,
        publish_time: publishTime,
        status: status,
        coordinates: coordinates,
        epages: epages,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperUpdateData);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getEPaperList(
    status,
    startDate,
    endDate,
    page,
    itemPerPage,
    titleKeyword,
    hashtagKeyword
) {
    var body = {
        status: status,
        start_date: startDate,
        end_date: endDate,
        page: page,
        item_per_page: itemPerPage,
        title_keyword: titleKeyword,
        hashtag_keyword: hashtagKeyword,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetList);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getEPaperDetails(ePaperID) {
    var body = {
        epaper_id: ePaperID,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetDetails);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getEPaperAnalysis(id, jwt) {
    var body = {
        user_id: id,
        jwt: jwt,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetAnalysis);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function getEPaperPerformance(id, jwt, page, itemPerPage) {
    var body = {
        user_id: id,
        jwt: jwt,
        page: page,
        item_per_page: itemPerPage,
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetPerformance);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: [],
    });
}

export async function ePaperDuplicatePage(ePaperID) {
    var body = {
        epaper_id: ePaperID
    };
    var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperDuplicatePage);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        result: [],
    });
}
