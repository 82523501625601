import React from "react";
import PropTypes from 'prop-types';
import "./PageNavigation.scss";
import NextIcon from "../../images/nextButton.svg";
import NextIconHover from "../../images/nextButtonHover.svg";
import PrevIcon from "../../images/prevButton.svg";
import PrevIconHover from "../../images/prevButtonHover.svg";
import FirstPageIcon from "../../images/firstPageButton.svg";
import FirstPageIconHover from "../../images/firstPageButtonHover.svg";
import LastPageIcon from "../../images/lastPageButton.svg";
import LastPageIconHover from "../../images/lastPageButtonHover.svg";
import { debugPrint } from "../../utilities/Utilities";

class PageNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: this.props.currentPage,
            backgroundColor: "",
            fontColor: ""
        };

        this.firstPage = this
            .firstPage
            .bind(this);
        this.prevPage = this
            .prevPage
            .bind(this);
        this.nextPage = this
            .nextPage
            .bind(this);
        this.lastPage = this
            .lastPage
            .bind(this);

        this.first = React.createRef();
        this.prev = React.createRef();
        this.next = React.createRef();
        this.last = React.createRef();

        this.range = 1;
    }

    getShowingPages() {
        var totalPages = [];
        var lowest = this.state.currentPage - this.range;
        var highest = this.state.currentPage + this.range;

        for (var j = 1; j <= this.range; j++) {
            totalPages.push(this.state.currentPage + j);
            totalPages.push(this.state.currentPage - j);
        }

        totalPages.push(this.state.currentPage);
        var updatedTotalPages = [];
        totalPages.forEach(eachPage => {
            if (eachPage <= 0) {
                updatedTotalPages.push(highest + Math.abs(eachPage - 1));
            } else if (eachPage > this.props.total) {
                updatedTotalPages.push(lowest - Math.abs(eachPage - this.props.total));
            } else {
                updatedTotalPages.push(eachPage);
            }
        });

        var finalTotalPages = [];
        updatedTotalPages.forEach((eachPage) => {
            if (eachPage > 0 && eachPage <= this.props.total) {
                finalTotalPages.push(eachPage);
            }
        });

        return finalTotalPages.sort(function (a, b) {
            return a - b;
        });
    }

    componentDidMount() {}

    onHover() {
        this.setState({backgroundColor: "#EE4139", fontColor: "white"});
    }

    onLeave() {
        this.setState({backgroundColor: "none", fontColor: "#00000050"});
    }

    firstPage() {
        this.setState({currentPage: 1}, () => {
          this
              .props
              .onPageChange(this.state.currentPage);
      });
    }

    nextPage() {

        this.setState({
            currentPage: Math.min(this.state.currentPage + 1, this.props.total)
        }, () => {
            this
                .props
                .onPageChange(this.state.currentPage);
        });
    }

    prevPage() {
        this.setState({
            currentPage: Math.max(this.state.currentPage - 1, 1)
        }, () => {
          this
              .props
              .onPageChange(this.state.currentPage);
      });
    }

    lastPage() {
        this.setState({currentPage: this.props.total}, () => {
          this
              .props
              .onPageChange(this.state.currentPage);
      });
    }

    jumpToPage(page) {
        this.setState({currentPage: page}, () => {
          this
              .props
              .onPageChange(this.state.currentPage);
      });
    }

    render() {
        return (
            <div
                style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <div className="page-navigation-parent">
                    {this.props.enableFirstLastButton
                        ? <div
                                className={`btn-frame first-btn ${this.state.currentPage === 1
                                ? "end-of-page"
                                : ""}`}
                                onClick={this.firstPage}
                                onMouseOver={e => {
                                this.first.src = FirstPageIconHover;
                            }}
                                onMouseOut={e => {
                                this.first.src = FirstPageIcon;
                            }}>
                                <img
                                    alt=""
                                    ref={e => this.first = e}
                                    style={{
                                    opacity: this.state.currentPage === 1
                                        ? 0.25
                                        : 1
                                }}
                                    src={FirstPageIcon}/>
                            </div>
                        : null}
                    <div
                        className={`btn-frame ${this.state.currentPage === 1
                        ? "end-of-page"
                        : ""} ${this.props.enableFirstLastButton
                            ? ""
                            : "first-btn"}`}
                        onClick={this.prevPage}
                        onMouseOver={e => {
                        this.prev.src = PrevIconHover;
                    }}
                        onMouseOut={e => {
                        this.prev.src = PrevIcon;
                    }}>
                        <img
                            alt=""
                            ref={e => this.prev = e}
                            style={{
                            opacity: this.state.currentPage === 1
                                ? 0.25
                                : 1
                        }}
                            src={PrevIcon}/>
                    </div>
                    {this
                        .getShowingPages()
                        .map((eachPage) => (
                            <div
                                key={`page_${eachPage}`}
                                className={`btn-frame btn-text ${this.state.currentPage === eachPage
                                ? "current-page"
                                : ""}`}
                                onClick={() => this.jumpToPage(eachPage)}>
                                {eachPage}
                            </div>
                        ))}
                    <div
                        className={`btn-frame ${this.state.currentPage === this.props.total
                        ? "end-of-page"
                        : ""} ${this.props.enableFirstLastButton
                            ? ""
                            : "last-btn"}`}
                        onClick={this.nextPage}
                        onMouseOver={e => {
                        this.next.src = NextIconHover;
                    }}
                        onMouseOut={e => {
                        this.next.src = NextIcon;
                    }}>
                        <img
                            alt=""
                            ref={e => this.next = e}
                            style={{
                            opacity: this.state.currentPage === this.props.total
                                ? 0.25
                                : 1
                        }}
                            src={NextIcon}/>
                    </div>
                    {this.props.enableFirstLastButton
                        ? <div
                                className={`btn-frame last-btn ${this.state.currentPage === this.props.total
                                ? "end-of-page"
                                : ""}`}
                                onClick={this.lastPage}
                                onMouseOver={e => {
                                this.last.src = LastPageIconHover;
                            }}
                                onMouseOut={e => {
                                this.last.src = LastPageIcon;
                            }}>
                                <img
                                    alt=""
                                    ref={e => this.last = e}
                                    style={{
                                    opacity: this.state.currentPage === this.props.total
                                        ? 0.25
                                        : 1
                                }}
                                    src={LastPageIcon}/>
                            </div>
                        : null}
                </div>
            </div>
        )
    }
}

PageNavigation.propTypes = {
    onPageChange: PropTypes.func,
    enableFirstLastButton: PropTypes.bool,
    total: PropTypes.number,
    currentPage: PropTypes.number
}

PageNavigation.defaultProps = {
    onPageChange: function (currentPage) {
        debugPrint("Current page is -> ", currentPage);
    },
    enableFirstLastButton: true,
    total: 10,
    currentPage: 1
};

export default PageNavigation;