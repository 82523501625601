import React from 'react';
import "./Tag.scss";
import PropTypes from 'prop-types';

class Tag extends React.Component
{
    constructor(props)
    {
        super(props);

        this.triggerTagEvent = this
            .triggerTagEvent
            .bind(this);
    }

    triggerTagEvent()
    {
        var event = new CustomEvent("search-hashtag", {"detail": this.props.label});

        window.dispatchEvent(event);
    }

    render()
    {
        return (
            <div className='tag' onClick={this.triggerTagEvent}>
                #{this.props.label}
            </div>
        );
    }
}

Tag.propTypes = {
    label: PropTypes.string
};

Tag.defaultProps = {
    label: ""
}

export default Tag;