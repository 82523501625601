import React from 'react';
import "./Pages.scss";
import LoadingScreen from "../widgets/LoadingScreen"


class TestPage extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
         
        };

    }
    

    render()
    
    {
        return (
            <div>
               <LoadingScreen/>
            </div>
        );
    }
}

export default TestPage;