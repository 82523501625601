const APIPaths = {
  apiEndPoint: "https://app.atvnewsonline.com/epaper/v2.1/dashboard/",
  pdfUpload: "epaper_upload",
  ePaperUpdateData: "epaper_update_data",
  login: "login",
  ePaperGetList: "epaper_get_list",
  ePaperGetDetails: "epaper_get_details",
  ePaperGetAnalysis: "epaper_get_analysis",
  ePaperGetPerformance: "epaper_get_performance",
  ePaperDuplicatePage: "epaper_duplicate_page",
};

export default APIPaths;
