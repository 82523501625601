import './App.css';
import {Switch, Route} from 'react-router';
import {Router} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import DashboardPage from './components/pages/DashboardPage';
import TestPage from './components/pages/TestPage';
import AddEPaperPage from './components/pages/AddEPaperPage';
import EPaperListPage from './components/pages/EPaperListPage';
import AnalyticsPage from './components/pages/AnalyticsPage';
import packageJson from '../package.json';
import { createBrowserHistory } from "history"

const browserHistory = createBrowserHistory()
function App() {
    return (
        <div>
            <Router history={browserHistory}>
                <Switch>
                    <Route path="/login" exact component={(LoginPage)}/>
                    <Route path="/dashboard" exact component={(DashboardPage)}/>
                    <Route path="/test" exact component={(TestPage)}/>
                    <Route path="/addepaper" exact component={(AddEPaperPage)}/>
                    <Route path="/analytics" exact component={(AnalyticsPage)}/>
                    <Route path="/epaperlist" exact component={(EPaperListPage)}/>
                    <Route path="/" exact component={(LoginPage)}/>
                </Switch>
            </Router >
            <div className='version' >v{packageJson.version}</div>
        </div>
    );
}

export default App;
