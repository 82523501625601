import React from 'react';
import PropTypes from 'prop-types';
import "./InputTag.scss";
import RemoveIcon from "../../images/remove_icon.svg"
import { debugPrint } from '../../utilities/Utilities';

class InputTag extends React.Component {
    render() {
        return (
            <div className={`input-tag ${this.props.highlight ? "highlight" : ""}`}>
                {`#${this.props.label}`}
                <img
                    className='input-tag-remove-button'
                    src={RemoveIcon}
                    alt="remove"
                    onClick={this.props.onRemove}/>
            </div>
        );
    }
}

InputTag.propTypes = {
    highlight: PropTypes.bool,
    label: PropTypes.string,
    onRemove: PropTypes.func
};

InputTag.defaultProps = {
    highlight: false,
    label: "-",
    onRemove: () => {debugPrint("Removing tag")}
}

export default InputTag;